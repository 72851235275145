<template>
  <div>

    <!-- <TheSidebar/> -->
    <CWrapper>
      <TheHeader />
      
      <section style="margin-top: 10rem">
        <div v-if="this.$route.params.id==14">{{ this.redirect_plan()}} </div>
        <div class="row">
          <div class="col-sm-12">
            <h5 class="ml-lg-5 text-dark font-style">Data List</h5>
          </div>
        </div>
        <div
          class="row m-lg-5 text-center shadow rounded p-lg-3"
          style="overflow-x: auto"
        >
          <div v-if="message != null" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
          <CRow>
           
            <CCol v-if="this.$route.params.id == 1" sm="12">
              
            </CCol>
            <CCol v-if="this.$route.params.id != 1"  sm="12">
              <CRow class="ml-2">
                <CCol sm="3">
                  <label>Country </label>

                  <b-form-select
                    v-model="searchdata.country"
                    :custom-label="customLabel"
                    :options="Countryoptions123"
                  ></b-form-select>
                </CCol>
                <CCol sm="3">
                  <CInput
                    type="text"
                    v-model="searchdata.search_filter"
                    placeholder="Search"
                    label="Search "
                  />
                </CCol>
                <CCol sm="3"
                  ><br />
                  <button
                    class="batn mt-lg-2 float-lg-left p-2"
                    @click="search()"  >
                    <b-icon icon="search" aria-hidden="true"></b-icon> Search
                  </button>
                </CCol>
                <CCol sm="3"
                  ><br />
                  <button class="batn mt-lg-2 p-2" @click="restfunction()">
                    <b-icon icon="search" aria-hidden="true"></b-icon> Reset
                    Filter
                  </button>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <table
              class="table w-100 ml-2"
              onmousedown="return false"
              onselectstart="return false"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th  scope="col">Country</th>
                 
                  <th scope="col">Company Name</th>
                  <th scope="col">View</th>
                  <!-- <th scope="col">Website</th>
                  <th scope="col">Contact Email</th>
                  <th scope="col">Contact Number</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  @contextmenu.prevent
                  v-for="(list, index) in plandatafetch"
                  :key="index"
                >
                  <td>{{ index + count + 1}}</td>
                  <td >{{ list.country_name }}</td>
                  <!-- <td >{{ list.country_name }}</td> -->
                  
                  <td
                    class="blurry-text"
                    v-if=" list.is_premium == 1 && !( list.clickable_user && list.clickable_user.includes(userdetect.user_id12)
                      )">
                     {{ list.company }}
                  </td>
                  <td 
                    v-if="
                      list.is_premium == 1 &&
                      list.clickable_user &&
                      list.clickable_user.includes(userdetect.user_id12)
                    "
                  >
                    {{ list.company }}
                  </td>
                  <td v-if="list.is_premium != 1">{{ list.company }}</td>
                  <td v-if="list.is_premium != 1 ">                
                    <button  @click="valueFetchModel(list.id)" class="bg-primary text-white batn"  v-b-modal.modal-detail >View Data</button>
                  </td>
                  <td  v-if="
                      list.is_premium == 1 &&
                      list.clickable_user &&
                      list.clickable_user.includes(userdetect.user_id12)
                    "  >                
                    <button class="bg-primary text-white batn" @click="valueFetchModel(list.id)" v-b-modal.modal-detail >View Data</button>
                  </td>
                  <td
                    v-if="list.is_premium == 1 && !( list.clickable_user && list.clickable_user.includes(userdetect.user_id12)
                      )">
                    <button
                      :value="list.id"
                      @click="GiveAccess(list.id)"
                      class="batn"
                    >
                      Premium
                    </button>
                  </td>
                </tr>
              </tbody>
                <b-modal size="xl" id="modal-detail" title="More Details" hide-footer>
                  <table class="table w-100 ml-2">
              <thead>
                <tr>
                  <th scope="col">Company Name</th>
                  <th scope="col">Website</th>
                  <th scope="col">Contact Email</th>
                  <th scope="col">Contact Number</th>
                </tr>
              </thead>
              <tbody>
                 <tr v-if="this.$route.params.id==14" >
                  <td>{{this.dataModelPlanPerticular.company}}</td>
                  <td>{{this.dataModelPlanPerticular.website}}</td>
                  <td>{{this.dataModelPlanPerticular.email}}</td>
                  <td>{{this.dataModelPlanPerticular.contact_number}}</td>
                 
                
                </tr>
                <tr >
                  <td>{{this.dataModelPlanPerticular.company}}</td>
                  <td>{{this.dataModelPlanPerticular.website}}</td>
                  <td>{{this.dataModelPlanPerticular.contact_email}}</td>
                  <td>{{this.dataModelPlanPerticular.contact_number}}</td>
                 
                
                </tr>
              </tbody>
                   </table>
              </b-modal>
              <span style="text-align: center" v-if="plandatafetch == ''"
                >No Data Found</span
              >
            </table>
            <div class="table w-100 ml-2">
              <Pagination
                v-if="this.$route.params.id != 1"
                :totalPages="totalpage"
                :perPage="20"
                :currentPage="currentPage"
                @pagechanged="onPageChange"/>
                <b-button v-if="this.$route.params.id == 1" class="bg-primary text-white" v-b-modal.modal-1>More Data</b-button>
                <b-modal id="modal-1" size="xl"  title="Upgrade Trial Plan To Premium Plans " hide-footer>
                   <section onmousedown="return false" onselectstart="return false">
      <b-row class="text-center pt-4 w-100">
        <h2 class="pink fw-bolder"><b>Plans</b></h2>
        <hr style="width: 18%; margin-left: 40%; margin-top: 5px" />
      </b-row>
      <b-row class="text-center w-100">
        <b-col cols="12" sm="4"> </b-col>
        <b-col cols="12" sm="4">
          <p style="font-weight: 700">
            Select the Plans which suits you !!
          </p>
        </b-col>
      </b-row>
      <b-card-group deck class="mb-3 px-5"  v-if="userlistdisplay.length>0">
        <b-card
       
          v-for="(list, index) in userlistdisplay.slice(1)"
          :key="index"
          :class="{
            backblue: (index + 1) % 2,
            background_effect: !((index + 1) % 2),
          }"
          cols="12"
          sm="4"
          class="shadow background_effect p-3 mb-5 rounded p-3 text-center"
        >
          <b-row class="" >
            <b-col class="text-center">
              <h2 class="" style="font-weight: 700">{{ list.plan_name }}</h2>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col class="text-left">
              <p>
                <b-row>
                  <b-col sm="8">
                    <b-icon icon="clock" aria-hidden="true"></b-icon>
                    Validity</b-col
                  >
                  <b-col sm="4"
                    ><span
                      >{{ list.plan_period }} {{ list.plan_period_type }}
                    </span>
                  </b-col>
                </b-row>
              </p>
            </b-col>
          </b-row>

          <hr />
          <b-row>
          
            <b-col class="text-left curser-pointer" v-if="list.id == 3">
              <p>
                <b-row id="tooltip-target-1">
                  <b-col sm="8">
                    <b-icon icon="map" aria-hidden="true"></b-icon>
                    <b-icon
                      icon="map"
                      aria-hidden="true"
                      class="tooltip tooltip-scroll"
                    ></b-icon>
                    Countries</b-col
                  >
                  <b-col sm="4"
                    ><span>{{ list.countriescount }} + </span>
                  </b-col>
                  <b-tooltip
                    target="tooltip-target-1"
                    triggers="hover"
                    class="bg-danger"
                    variant="danger"
                  >
                    <div class="w-100 list-style-none wrapper" data-html="true">
                      <div class="tooltip-text">
                        <span class="">
                          <b>Gulf</b> - Uae,Syria,Bahrain,Lebanon
                        </span>
                        <hr />
                        <span>
                          <b>East Africa</b> - <br />
                          KENYA, Uganda, Madagascar, Ethiopia, Djibouti, Malawi,
                          Zambia, Rwanda, Zimbabwe, Mauritius, Botswana,
                          Burundi, Sudan, Seychelles</span>
                        <hr />
                        <br />
                        <span>
                          <b>West Africa</b> -
                          Naminia,Gabon,Nigeria,Cameroon,Benin,Mali,Burkina,Faso,Gambia,Niger </span
                        ><br />
                        <hr />
                        <span>
                          <b>Pacific</b> - Cambodia, Myanmar, Sri Lanka, Brunie,
                          Maldives, Bangladesh </span
                        ><br />
                        <hr />
                        <span>
                          <b>Bulkan & Europe</b> - Bulgaria, Slovenia, Latvia,
                          Montenegro, Austria, Hungary, Cyprus, Lithuania,
                          Belarus, Ukraine </span
                        ><br />
                        <hr />
                        <span>
                          <b>South America</b> - Uruguay, Paraguay, Bolivia,
                          Suriname, Ecuador, Nicaragua
                        </span>
                      </div>
                    </div>
                  </b-tooltip>
                </b-row>
              </p>
            </b-col>
            <b-col class="text-left" v-if="list.id == 4">
              <p>
                <b-row id="tooltip-target-4">
                  <b-col sm="8">
                    <b-icon icon="map" aria-hidden="true"></b-icon>
                    <b-icon 
                      icon="map"
                      aria-hidden="true"
                      class="tooltip tooltip-scroll"
                    ></b-icon>
                    Countries</b-col
                  >
                  <b-col sm="4"
                    ><span>{{ list.countriescount }} + </span>
                  </b-col>
                  <b-tooltip
                    target="tooltip-target-4"
                    triggers="hover"
                    class="bg-danger"
                    variant="danger"
                  >
                    <div class="w-100 list-style-none wrapper" data-html="true">
                      <div class="tooltip-text">
                        <span class="">
                          <b>Silver Plan Countries +</b><br />
                          <hr />
                          <b>Gulf</b> - Saudi Arabia, Yemen, Qatar, Jordan
                        </span>
                        <hr />
                        <span>
                          <b>East Africa</b> - <br />
                          Mozambique, Tanzania</span
                        >
                        <hr />
                        <br />
                        <span>
                          <b>West Africa</b> - Angola, Republic Of Congo,
                          Dr.Congo, Togo, Ghana, Cote D'Ivoire, Mauritania,
                          Guinea, Morocco, Algeria </span
                        ><br />
                        <hr />
                        <span> <b>Pacific</b> - South Korea, Philippines </span
                        ><br />
                        <hr />
                        <span>
                          <b>Bulkan & Europe</b> - Portugal, Greece, Serbia,
                          France, Macedonia, Netherlands, Bosnia
                        </span>
                        <br />
                        <hr />
                        <span>
                          <b>South America</b> - Chile, Brazil, Peru, Costa
                          Rica, Panama
                        </span>
                      </div>
                    </div>
                  </b-tooltip>
                </b-row>
              </p>
            </b-col>
            <b-col class="text-left curser-pointer" v-if="list.id == 5">
              <p>
                <b-row id="tooltip-target-5">
                  <b-col>
                    <b-icon icon="map" aria-hidden="true"></b-icon>
                    <b-icon sm="8"
                      icon="map"
                      aria-hidden="true"
                      class="tooltip tooltip-scroll"
                    ></b-icon>
                    Countries</b-col
                  >
                  <b-col sm="4"
                    ><span>{{ list.countriescount }} + </span>
                  </b-col>
                  <b-tooltip
                    target="tooltip-target-5"
                    triggers="hover"
                    class="bg-danger"
                    variant="danger"
                  >
                    <div class="w-100 list-style-none wrapper" data-html="true">
                      <div class="tooltip-text">
                        <span>
                          <b>Gold Plan Countries </b> + <br />
                          <hr />
                          <b>North America</b> - USA, Canada </span
                        ><br />
                        <hr />
                        <span>
                          <b>Gulf</b> - Kuwait, Iraq, Israel/Palestine, Oman </span
                        ><br />
                        <hr />
                        <span>
                          <b>East Africa</b> - <br />
                          South Africa</span
                        >
                        <hr />
                        <br />
                        <span>
                          <b>West Africa</b> - Tunisia, Libya, Senegal,Liberia,
                          Sierra Leone </span
                        ><br />
                        <hr />
                        <span>
                          <b>Pacific</b> - Thailand, Vietnam, Indonesia, New
                          Zeland, Australia </span
                        ><br />
                        <hr />
                        <span>
                          <b>Bulkan & Europe</b> - Kosovo, UK, Germany, Belgium,
                          Albania, Poland, Romania, Croatia, Italy, Spain,
                          Ireland </span
                        ><br />
                        <hr />
                        <span>
                          <b>South America </b> - Mexico, Colombia, Guatemala,
                          Haiti, Dominican Republic </span
                        ><br />
                      </div>
                    </div>
                  </b-tooltip>
                </b-row>
              </p>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col class="text-left">
              <p>
                <b-row>
                  <b-col sm="8">
                    <b-icon icon="credit-card" aria-hidden="true"></b-icon>
                    Premium Contacts</b-col
                  >
                  <b-col sm="4"
                    ><span>{{ list.prem_contact }} </span>
                  </b-col>
                </b-row>
              </p>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col class="text-left">
              <p>
                <b-row>
                  <b-col sm="8"
                    ><b-icon icon="headset" aria-hidden="true"></b-icon> Support
                  </b-col>
                  <b-col sm="4">24/7 </b-col>
                </b-row>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-white btn-three34">
              <span v-if="isLoggedIn && list.id != 1" class="text-white" :v-model="cartvalue">
                <a href="javascript:;" @click="goToCart(list.id)"
                  >Buy Now</a></span>
            </b-col>
            <div v-if="list.id == 5" class="" id="Inquiry"></div>
          </b-row>
        </b-card>
      </b-card-group>
    </section>
                </b-modal>
            </div>
          </CRow>
        </div>
      </section>
    <div>
    <b-modal  label="" size="lg" class="modal fade"  ref="my-modal"  id="modal-subscribe"  hide-footer hide-header  tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"> 
      <button type="button" style="color: #e62e53"  class="close" data-dismiss="modal"  aria-label="Close" ><span @click="closepopup()" aria-hidden="true">&times;</span>
      
          </button>
          <section onmousedown="return false" onselectstart="return false">
      <b-row class="text-center w-100">
        <h2 class="pink fw-bolder"><b>Premium Contacts </b></h2>
        <hr style="width: 18%; margin-left: 40%; " />
      </b-row>
      <b-row class="text-center w-100">
        <b-col cols="12" sm="4"> </b-col>
        <b-col cols="12" sm="4">
          <p style="font-weight: 700">
            Select the Plans which suits you !!
          </p>
        </b-col>
      </b-row>
      <b-row class="gap-5 justify-content-center">
        <b-col
          cols="6"
          sm="4"
          class="shadow background_effect p-3 mb-5 rounded p-3 text-center"
        >
          <b-row class="" >
            <b-col class="text-center">
              <h2 class="" style="font-weight: 700">Rs 4000</h2>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col class="text-left">
              <p>
                <b-row>
                  <b-col sm="8">
                    <b-icon icon="credit-card" aria-hidden="true"></b-icon>
                    Premium Contacts</b-col
                  >
                  <b-col sm="4"
                    ><span>100</span>
                  </b-col>
                </b-row>
              </p>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col class="text-left">
              <p>
                <b-row>
                  <b-col sm="8"
                    ><b-icon icon="headset" aria-hidden="true"></b-icon> Support
                  </b-col>
                  <b-col sm="4">24/7 </b-col>
                </b-row>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-white btn-three34">
              <span>
                <a href="tel:8780604705" 
                  >Call Us</a></span>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="6"
          sm="4"
          class="shadow background_effect p-3 mb-5 rounded p-3 text-center"
        >
          <b-row class="" >
            <b-col class="text-center">
              <h2 class="" style="font-weight: 700">Rs 5000</h2>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col class="text-left">
              <p>
                <b-row>
                  <b-col sm="8">
                    <b-icon icon="credit-card" aria-hidden="true"></b-icon>
                    Premium Contacts</b-col
                  >
                  <b-col sm="4"
                    ><span>150</span>
                  </b-col>
                </b-row>
              </p>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col class="text-left">
              <p>
                <b-row>
                  <b-col sm="8"
                    ><b-icon icon="headset" aria-hidden="true"></b-icon> Support
                  </b-col>
                  <b-col sm="4">24/7 </b-col>
                </b-row>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-white btn-three34">
              <span>
                <a href="tel:8780604705" 
                  >Call Us </a></span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>
  </b-modal>
</div>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "../../containers/TheSidebar";
import TheHeader from "../../containers/TheHeader";
import TheFooter from "../../containers/TheFooter";
import Pagination from "../../components/Pagination";
import Plans from "../../components/plans";
import axios from "axios";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      popup_premium:null,
      dataModelPlanPerticular:[],
      dataModelPlanPerticular:[],
      userlistdisplay: [],
      cartvalue:null,
      message: null,
      Countryoptions123: null,
      showDismissibleAlert: false,
      totalpage: 1,
      plandatafetch: [],
      perPage: 20,
      currentPage: 1,
      count:0,
      userdetect: {
        user_id12: localStorage.getItem("user"),
        param_plan: null,
        data_id: null,
        page: 1,
        counData: null,
        searchData123: null,
      },
      searchdata: {
        search_filter: null,
        country: null,
      },
      currentPage: 1,
      totalPageCount: 0,
      searchResult: null,
    };
  },
  name: "Dashboard",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    Pagination,
    Plans
  },
  computed: {
    ...mapGetters(["user"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
    rows() {
      return this.plandatafetch.length;
    },
  },

  created() {
    if (this.isLoggedIn) {
      this.getUserplandatalist(1);
      this.fetchcountry();
      this.fetchplanDynamic();
    }
  },
mounted() {
    if (this.popup_premium != 1) {
      window.setInterval(() => {
        this.onWindowLoad_premium();
      }, 2000);
    } else {
      this.closepopup();
    }
  },
  watch: {
    popup: function (val) {
      if (val == 1) {
        this.closepopup();
      }
    },
  },
  // created() {
  //   this.fetchplanDynamic();
  //   this.getUserplan() ;
  // },
  methods: {
    ...mapActions([
      "getUserplanlistperticular",
      "GiveaccessDetail",
      "CountryDropDynamic",
      "CountryWiseData",
      "CountryWiseDatasearch",
      "FlagUpdateData",
      "addToCart12","FetchModelData"
    ]),
   
        
    restfunction() {
      location.reload();
    },
     onWindowLoad_premium() {
      // localStorage.setItem("popup_premiumcontact", 0);
      if (this.popup_premium != 1 && localStorage.getItem("popup_premiumcontact") != 1 && this.$route.params.id !=1  ) {
        this.$refs["my-modal"].show();
      }
    },
    closepopup() {
      this.$refs["my-modal"].hide();
      this.popup_premium = 1;
      localStorage.setItem("popup_premiumcontact", 1);
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    goToCart(pid) {
      let user = localStorage.getItem("user");
      this.addToCart12({ id: pid, user_id: user }).then((response) => {
        window.location.href = "/cart";
      });
    },
     fetchplanDynamic() {
      axios.get(process.env.VUE_APP_API_URL + "PlanList").then((resp) => {
        this.userlistdisplay = resp.data.planlist;
      });
    },
    fetchcountry() {
       this.userdetect.param_plan = this.$route.params.id;
      this.CountryDropDynamic(this.userdetect).then((resp) => {
        let arr = [];
        resp.data.data.forEach((res) => {
          arr.push({ text: res.country_name, value: res.id });
        });
        this.Countryoptions123 = arr;
      });
    },
    customLabel(www) {
      return `${www.text}`;
    },
    getUserplandatalist(page) {
      this.userdetect.user_id12 = localStorage.getItem("user");
      this.userdetect.param_plan = this.$route.params.id;
      this.userdetect.page = page;
      this.userdetect.counData = this.searchdata.country;
    
      this.userdetect.searchData123 = this.searchdata.search_filter;
      this.getUserplanlistperticular(this.userdetect).then((res) => {
      this.totalpage = Math.ceil(res.data.data.total / 20);
      //console.log(res.data.data.countrydatamult.data)
      this.plandatafetch = [].concat.apply(
        [],
        res.data.data.countrydatamult.data
      );
      
      });
      this.count=0;
    },
    GiveAccess(id) {
      this.userdetect.data_id = id;
      this.userdetect.param_plan = this.$route.params.id;
      this.userdetect.user_id12 = localStorage.getItem("user");
      this.GiveaccessDetail(this.userdetect).then((resp) => {
        this.message = resp.data.message;
        if (this.message == "All Creadits are used to buy contact us") {
          this.showDismissibleAlert = true;
        } else if (this.message == "You dont Have Creadits To see this ") {
              this.onWindowLoad_premium() ;
          this.showDismissibleAlert = true;
        } else {
          this.getUserplandatalist();
        }
      });
    },
    search() {
      this.userdetect.user_id12 = localStorage.getItem("user");
      this.userdetect.param_plan = this.$route.params.id;
      this.userdetect.page = 1;
      this.userdetect.counData = this.searchdata.country;
        console.log(this.userdetect);
      this.userdetect.searchData123 = this.searchdata.search_filter;
      this.getUserplanlistperticular(this.userdetect).then((res) => {
        this.totalpage = res.data.data.total;
        this.plandatafetch = [].concat.apply(
          [],
          res.data.data.countrydatamult.data
        );
      });
    },
    onPageChange(page) {

      this.getUserplandatalist(page);
      this.currentPage = page;
      this.count = 20*(page-1);
    },
    valueFetchModel(id){
        let planid1 = this.$route.params.id;
      this.FetchModelData({planid:id ,plan:planid1}).then((res)=> {
        this.dataModelPlanPerticular=res.data.data.countrydatamult;
      })
    },
    redirect_plan(){
    window.location.href="/plansdata/14";
  }
  },
  
};
</script>
<style scoped>
.batn {
  background: #1e38a7;
  color: white;
  border: none;
  border-radius: 28px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.batn:hover {
  background: #002ef8;
  color: white;
  border: none;
  border-radius: 28px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.background_effect:nth-child(odd) {
  background: #1e38a7 !important;
  color: #f8fbfd;
}
.background_effect:nth-child(odd) span:hover a {
  color: white;
}
.background_effect:nth-child(even) span:hover a {
  color: #fa2b56;
}
.bg-pink {
  background-color: #fa2b56;
}
.pink {
  color: #fa2b56;
}
.btn-three34 span a {
  text-decoration: none;
}
.btn-three34 span {
  color: #fff;
  font-weight: 700;
  transition: all 0.5s;
  position: relative;
  padding: 0.5rem 1rem;
  background-color: #fa2b56;
  border-radius: 25px;
}
.btn-three34:hover span {
  background: none;
  border: 1px solid #fa2b56;
  color: #fa2851 !important;
}
.blurry-text {
   color: transparent;
   text-shadow: 0 0 5px rgba(0,0,0,0.5);
}
</style>